import React, { useEffect, useState, forwardRef, useImperativeHandle, useRef } from 'react';
import InputMask from 'react-input-mask';

import {
	empty
} from '__globals/global-functions';

import {
	isOfAge, isDateGreaterThanDate, isDateLessThanDate
} from '__globals/date-functions';

const DateOfBirth = (props) => {

	/* LOCAL VARIABLES */
	const [currentValue, setCurrentValue] = useState('');
	const onChangeCurrentValue = e => {
		setCurrentValue(e.target.value);
	}

	const [tabIndex, setTabIndex] = useState(1);

	const [placeholder, setPlaceholder] = useState("DATE OF BIRTH");

	const [errorMsgRequired, setErrorMsgRequired] = useState("Please include your date of birth.");

	const [required, setRequired] = useState(true);

	const [ageGate, setAgeGate] = useState(0);

	/* FUNCTIONS */
	/* Checks the validations for this element */
	const checkValidation = () => {
		//return currentValue + " | " + currentValue.length + " | " + currentValue.indexOf(" ");
		if ( required ) {
			if ( empty(currentValue) || (currentValue.length === 0) || (currentValue.indexOf(" ") > 0) ) {
				return errorMsgRequired;
			} else {
				const givenDate = Date.parse(currentValue);
				const reasonableLowThreshold = Date.parse("01/01/1901" );
				const reasonableHighThreshold = Date.parse("01/01/2030" );
				if ( isDateGreaterThanDate(givenDate, reasonableHighThreshold) ||
					isDateLessThanDate(givenDate, reasonableLowThreshold) ) {
					return "The birthdate given does not appear to be valid.";
				} else if ( (ageGate > 0) && !isOfAge(givenDate, ageGate) ) {
					return "You must be " + ageGate + " to enter this site.";
				} else {
					return null;
				}
			}
		}
	}

	/* HOOKS */
	/* Check validation and send data to the parent whenever the currentValue changes */
	useEffect(() => {
		let valMessage = checkValidation();
		let isValid = true;
		if ( !empty(valMessage) ) {
			isValid = false;
		}
		let vJSON = {};
		vJSON.name = props.name;
		vJSON.val = currentValue;
		vJSON.isValid = isValid;
		vJSON.valMessage = valMessage;
		props.updateFormValues( vJSON );
	}, [currentValue, errorMsgRequired] );

	/* Set additional fields from the promotion "fieldsInUse" config */
	useEffect(() => {
		if ( !empty(props.fieldsInUse) && !empty(props.fieldsInUse[props.name]) ) {
			if ( !empty(props.fieldsInUse[props.name].placeholder) && !empty(props.language) ) {
				setPlaceholder( props.fieldsInUse[props.name].placeholder[props.language] );
			}
			if ( !empty(props.fieldsInUse[props.name].errorMsgRequired) && !empty(props.language) ) {
				setErrorMsgRequired( props.fieldsInUse[props.name].errorMsgRequired[props.language] );
			}
			if ( !empty(props.fieldsInUse[props.name].required) ) {
				setRequired( props.fieldsInUse[props.name].required );
			}
			if ( !empty(props.fieldsInUse[props.name].tabIndex) ) {
				setTabIndex( props.fieldsInUse[props.name].tabIndex );
			}
			if ( !empty(props.fieldsInUse[props.name].ageGate) ) {
				setAgeGate( props.fieldsInUse[props.name].ageGate );
			}
		}
	}, [props.fieldsInUse] );

	return (
		<div id={"dv_" + props.name} className={"cls-" + props.name}>
			<InputMask
				name={props.name}
				type="text"
				tabIndex={tabIndex}
				placeholder={placeholder}
				value={currentValue}
				onChange={onChangeCurrentValue}
				mask="09/19/2399"
				formatChars={{
					'0': '[0-1]',
					'1': '[0-3]',
					'2': '[1-2]',
					'3': '[0,9]',
					'9': '[0-9]',
					'A': '[a-zA-Z]',
					'*': '[0-9a-zA-Z]'
				}}
				maskChar=" "
			/>
		</div>
	);

	// if (props.promotion.entryForm.fields.dateOfBirth.show) {
	//
	// 	return (
	// 		<div className={"dateOfBirth " + props.promotion.entryForm.fields.dateOfBirth.errClass}>
	// 		<InputMask
	// 					mask={[/[0-1]/, /[0-9]/, ' ', '-', ' ',/[0-3]/, /[0-9]/, ' ', '-', ' ', /[1-2]/, /[0,9]/, /[0,1]/, /[0-9]/]}
	// 					name="dateofbirth"
	// 					type="text"
	// 					value={props.value}
	// 					tabIndex={props.promotion.entryForm.fields.dateOfBirth.tabIndex}
	// 					onChange={props.onChange}
	// 					placeholder={props.promotion.entryForm.fields.dateOfBirth.placeholder} />
	// 		</div>
	// 	);
	//
	// } else {
	//
	// 	return null;
	//
	// }
};

export default DateOfBirth;

