import React, {useEffect, useState, useRef} from 'react';

import { TitleComponent } from '_components/TitleComponent/TitleComponent';
import {ActionButtons} from "_components/ActionButtons/ActionButtons";
import {PageCopy} from "_components/PageCopy/PageCopy";
import {PromocracyLogo} from "_components/PromocracyLogo/PromocracyLogo";
import PageFlow from "_pages/__routes/PageFlow";

import * as GC from "__globals/GLOBAL-CONSTANTS";
import { logIt } from '__globals/global-functions';
import {useDispatch, useSelector} from "react-redux";
import {executeAction} from "../../../../__globals/actions";

export const ThankYouDefault = props => {

    logIt( GC.PAGE_THANK_YOU_DEFAULT + " PAGE IS CALLED", null, false );

    const dispatch = useDispatch();

    const promotion = useSelector(state => state.promotion);
    //logIt( "PROMOTION at " + GC.PAGE_THANK_YOU_DEFAULT, promotion, true );

    // LOCAL VARIABLES
    const [action, setAction] = useState('');
    const [value, setValue] = useState('');

    const [goNext, setGoNext] = useState(false);

    const doAction = ( a, v ) => {
        if ( a.toUpperCase().trim() === GC.ACTION_NAVIGATE ) {
            setAction(a);
            setValue(v);
            setGoNext(true);
        } else {
            //dispatch( executeAction(a, v) );
            executeAction(a, v)
        }
    }

    if (goNext) {

        return (<PageFlow page={GC.PAGE_THANK_YOU_DEFAULT} error={null} action={action} value={value} />);

    } else {

        return (
            <div id='dv_thank_you_default'>

                <PageCopy
                    promotion={promotion}
                    configKey={"thankYouScreen"}
                    jsonKey={GC.TY_DEFAULT}
                    entry={props.entry}
                />

                <ActionButtons
                    promotion={promotion}
                    configKey={"thankYouScreen"}
                    jsonKey={GC.TY_DEFAULT}
                    doAction={doAction}
                />

                <PromocracyLogo promotion={promotion} />

            </div>
        );

    }
}

export default ThankYouDefault;