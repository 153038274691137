import { useDispatch } from 'react-redux';
import {empty} from "./global-functions";
import {updateEntry} from "_features/entries/entry-slice";
import {goNextPage} from "./page-flow";
import {useNavigate} from "react-router-dom";

const ACTION_LIST = ["URL", "URLBLANK", "PRINT", "THANKYOU", "HOME","NAVIGATE"];

export function executeAction( action, value, entryAdd ) {

    // URL Action
    if ( action.toUpperCase().trim() === "URL" ) {
        if ( !empty(value) ) {
            window.location.href = value;
            return;
        }
    }

    // URLBlank Action
    if ( action.toUpperCase().trim() === "URLBLANK" ) {
        if ( !empty(value) ) {
            window.open(value, '_blank');
            return;
        }
    }

    // Print Action
    if ( action.toUpperCase().trim() === "PRINT" ) {
        window.print();
        return;
    }

    // Thank You Action
    if ( action.toUpperCase().trim() === "THANKYOU" ) {
        navigate( "/thank-you" );
        return;
    }

    // Navigate
    if ( action.toUpperCase().trim() === "NAVIGATE" ) {
        navigate( "/"+value );
        return;
    }

}

export function isInternalAction( action ) {
    if ( ACTION_LIST.indexOf( action.toUpperCase().trim() ) >= 0 ) {
        return true;
    } else {
        return false;
    }
}

const handleActionButton1 = () => {

}
