/**
 * NAME: page-flow.js
 * DESCRIPTION: A global set of functions to help determine page flow.
 * AUTHOR: Chuck Gorder
 * CREATED: 2022-06-22
 */
//import { useNavigate } from "react-router-dom";
//let navigate = useNavigate();


import {
	logIt,
	DEBUG,
	empty,
	isTestMode,
	doesVariationExist,
	isVariationOn,
	isNoReg,
	isPickYourPrize,
	useSplash
} from '__globals/global-functions';

import {
	buildError
} from '__globals/error-handling.js'

import * as GC from '__globals/GLOBAL-CONSTANTS.js';
import {PROMO_TYPE_IW_NO_CLICK_REVEAL} from "__globals/GLOBAL-CONSTANTS.js";
import {isPromocracyOn} from "./promocracy-functions";


/** DETERMINE WHAT THE NEXT PAGE SHOULD BE */
export function goNextPage( promotion, currentPage, error, uid ) {


	// CHECK START AND END
	if ( !isTestMode(promotion) ) {
		if ( !hasPromoStarted(promotion.configuration.config.startDateMS) ) {
			window.location = "/#/coming-soon";
			return;
		}
		if ( hasPromoEnded(promotion.configuration.config.endDateMS) ) {
			window.location = "/#/promo-ended";
			return;
		}
	}

	// CHECK FOR ERRORS
	handleErrorFlow( promotion, currentPage, error );

	// CHECK PAGE URLS
	if ( checkPageUrls( promotion, currentPage ) ) {
		return;
	}

	// HANDLE PRE-ENTRY FLOW
	if ( handlePreEntryFlow( promotion, currentPage ) ) {
		return;
	}

	// HANDLE ENTRY FLOW
	if ( handleEntryFlow( promotion, currentPage ) ) {
		return;
	}

	// HANDLE POST ENTRY FLOW
	if ( handlePostEntryFlow( promotion, currentPage ) ) {
		return;
	}

	alert(JSON.stringify(promotion.variables))

	// CHOOSE FLOW
	if ( promotion.configuration.type.value === GC.PROMO_TYPE_SIMPLE_SWEEPS ) {
		handleSimplePromoFlow( promotion, currentPage, error );
	} else if ( promotion.configuration.type.value === GC.PROMO_TYPE_IW_MATCH_CONFIGURABLE ) {
		handleIWMatchConfigurableFlow( promotion, currentPage, error );
	} else if ( promotion.configuration.type.value === GC.PROMO_TYPE_IW_MATCH ) {
		handleIWMatchFlow( promotion, currentPage, error );
	} else if ( promotion.configuration.type.value === GC.PROMO_TYPE_IW_WHEEL ) {
		handleIWWheelFlow(promotion, currentPage, error);
	} else if ( promotion.configuration.type.value === GC.PROMO_TYPE_IW_SLOT ) {
		handleIWSlotFlow(promotion, currentPage, error);
	} else if ( promotion.configuration.type.value === GC.PROMO_TYPE_IW_NO_CLICK_REVEAL ) {
		handleIWNoClickRevealFlow(promotion, currentPage, error);
	}
	
}

/** HANDLE ERROR FLOW **/
function handleErrorFlow( promotion, currentPage, error ) {
	if ( empty(promotion) || empty(promotion.configuration?.config) ) {	// NEED A VALID PROMOTION
		let error = buildError( "No promotion was found.", "");
		// TODO: Need to set the error here?
		window.location = '/#/error';
	}
	if ( !empty(error)  && error.status === "error" ) {
		logIt( "ERROR IN HANDLE ERROR FLOW", error, true );
		if (error.errors[0].message === "Already Entered") {
			if (promotion.configuration.type.value === GC.PROMO_TYPE_SIMPLE_SWEEPS) {
				window.location = "/#/already-entered";
			} else {
				window.location = "/#/already-played";
			}
		} else if (error.errors[0].message === "Already Won") {
			window.location = "/#/already-won";
		} else {
			window.location = '/#/error';
		}
	}
}

/** CHECK PAGE URLS **/
function checkPageUrls( promotion, currentPage ) {
	if ( window.location.href.indexOf("coming-soon") >= 0 ) {
		window.location = "/#/coming-soon";
		return true;
	}
	if ( window.location.href.indexOf("already-played") >= 0 ) {
		window.location = "/#/already-played";
		return true;
	}
	if ( window.location.href.indexOf("already-entered") >= 0 ) {
		window.location = "/#/already-entered";
		return true;
	}
	if ( window.location.href.indexOf("already-won") >= 0 ) {
		window.location = "/#/already-won";
		return true;
	}
	if ( window.location.href.indexOf("promo-ended") >= 0 ) {
		window.location = "/#/promo-ended";
		return true;
	}
	if ( window.location.href.indexOf("splash") >= 0 ) {
		window.location = "/#/splash";
		return true;
	}
	if ( window.location.href.indexOf("prizes") >= 0 ) {
		window.location = "/#/prizes";
		return true;
	}
}

/** HANDLE PRE-ENTRY FLOW **/
function handlePreEntryFlow( promotion, currentPage ) {

	if ( currentPage === GC.PAGE_APP) {
		if ( useSplash(promotion) ) {
			window.location = "/#/splash";
			return true;
		}
	}

}

/** HANDLE ENTRY FLOW **/
function handleEntryFlow( promotion, currentPage ) {
	if ( currentPage === GC.PAGE_APP || currentPage === GC.PAGE_SPLASH || currentPage === GC.PAGE_GNPREDIR ) {
		if ( isNoReg(promotion) ) {
			window.location = '/#/enter-nr';
			return true;
		} else {
			alert("here")

			//navigate("/enter");
			//window.location = '/enter';
			return true;
		}
	} else if ( currentPage === GC.PAGE_GNPREDIR ) {
		window.location = '/enter';
		return true;
	}

}

/** HANDLE POST-ENTRY FLOW **/
function handlePostEntryFlow( promotion, currentPage ) {

	if ( currentPage === GC.PAGE_ENTER || currentPage === GC.PAGE_ENTER_NOREG ) {
		if ( isPickYourPrize(promotion) ) {
			window.location = "/#/pick-your-prize";
			return true;
		}
	}

}

/** PAGE FLOW - SIMPLE SWEEPS */
function handleSimplePromoFlow( promotion, currentPage, error ) {

	if ( currentPage === GC.PAGE_ENTER || currentPage === GC.PAGE_ENTER_NOREG || currentPage === GC.PAGE_PICK_PRIZE ) {
		window.location = "/#/thank-you";
	}

}

/** PAGE FLOW - IW MATCH CONFIGURABLE */
function handleIWMatchConfigurableFlow( promotion, currentPage ) {

	if ( currentPage === GC.PAGE_ENTER || currentPage === GC.PAGE_ENTER_NOREG || currentPage === GC.PAGE_PICK_PRIZE ) {
		window.location = "/#/iw-match-c";
	} else if ( currentPage === GC.PAGE_IW_MATCH_CONFIGURABLE ) {
		window.location = "/#/thank-you";
	}

}


/** PAGE FLOW - IW MATCH */
function handleIWMatchFlow( promotion, currentPage ) {

	if ( currentPage === GC.PAGE_ENTER || currentPage === GC.PAGE_ENTER_NOREG || currentPage === GC.PAGE_PICK_PRIZE ) {
		window.location = "/#/iw-match";
	} else if ( currentPage === GC.PAGE_IW_MATCH ) {
		window.location = "/#/thank-you";
	}

}

/** PAGE FLOW - IW WHEEL */
function handleIWWheelFlow( promotion, currentPage ) {

	if ( currentPage === GC.PAGE_ENTER || currentPage === GC.PAGE_ENTER_NOREG || currentPage === GC.PAGE_PICK_PRIZE ) {
		window.location = "/#/iw-wheel";
	} else if ( currentPage === GC.PAGE_IW_WHEEL ) {
		window.location = "/#/thank-you";
	}

}

/** PAGE FLOW - IW SLOT */
function handleIWSlotFlow( promotion, currentPage ) {

	if ( currentPage === GC.PAGE_ENTER || currentPage === GC.PAGE_ENTER_NOREG || currentPage === GC.PAGE_PICK_PRIZE ) {
		window.location = "/#/iw-slot";
	} else if ( currentPage === GC.PAGE_IW_SLOT ) {
		window.location = "/#/thank-you";
	}

}

/** PAGE FLOW - IW NO CLICK REVEAL */
function handleIWNoClickRevealFlow( promotion, currentPage ) {

	if ( currentPage === GC.PAGE_ENTER || currentPage === GC.PAGE_ENTER_NOREG || currentPage === GC.PAGE_PICK_PRIZE ) {
		window.location = "/#/iw-no-click-reveal";
	} else if ( currentPage === GC.PAGE_IW_NO_CLICK_REVEAL ) {
		window.location = "/#/thank-you";
	}

}



/** DOES THE PROMOTION HAVE A CONFIG SECTION */
function hasConfig( promotion ) {
	 if ( !empty(promotion) ) {
		 if ( !empty(promotion.configuration) ) {
			 if ( !empty(promotion.configuration.config) ) {
				 return true;
			 }
		 }
	 }
	 return false;
}


