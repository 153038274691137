import React, { useRef, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import Countdown from 'react-countdown';

import {getRandomUrlAppendage} from "__globals/global-functions";
var addOn = getRandomUrlAppendage("rd");

export const Countdowner = props => {

    const countdownComplete = () => {
        return( <span>You are good to go!</span> );
    }

    const timerRef = useRef(null);

    // useEffect(() => {
    //     const targetElement = timerRef.current;
    //     if ( targetElement ) {
    //         //alert("it's alive")
    //     }
    // },[props]);

    return (
        <React.Fragment>
            {props.countdownOn && <div id="dv_countdown" ref={timerRef} >
                <Countdown date={props.countdownTarget}>
                    {countdownComplete()}
                </Countdown>
            </div>}
        </React.Fragment>
    );

}