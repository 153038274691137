import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReCAPTCHA from "react-google-recaptcha";
import { GoogleReCaptchaProvider, GoogleReCaptcha, useGoogleReCaptcha } from "react-google-recaptcha-v3";

import FirstName from './form-elements/FirstName';
import LastName from './form-elements/LastName';
import EmailAddress from './form-elements/EmailAddress';
import Address1 from './form-elements/Address1';
import Address2 from './form-elements/Address2';
import City from './form-elements/City';
import State from './form-elements/State';
import PostalCode from './form-elements/PostalCode';
import PhoneInput from "./form-elements/PhoneInput";
import DateOfBirth from "./form-elements/DateOfBirth";
import UserId from './form-elements/UserId';
import OptIn from "./form-elements/OptIn";
import CustomText from './form-elements/CustomText';
import CustomSelect from './form-elements/CustomSelect';
import ReadRules from "./form-elements/ReadRules";
import AutoOptIn from "./form-elements/AutoOptIn";

// import {
// 	GoogleReCaptcha,
// 	useGoogleReCaptcha
// } from 'react-google-recaptcha-v3';
	
import {
	addCSSClass,
	removeCSSClass
} from '__globals/css-functions';

import {
	logIt,
	empty
} from '__globals/global-functions';

import {
	getJsonContent,
	setContent
} from '__globals/copy-functions';

import { 
	postEntry,
	fetchToken 
} from '_features/entries/entry-slice';
import {
	retrievePromocracyDefaultDisplayName,
	retrievePromocracyOverallSponsorAddOn,
	retrievePromocracyParticipant
} from "../../../../__globals/promocracy-functions";
import {getGameName} from "../../../../__globals/iw-functions";

var sanitizeHtml = require('sanitize-html');

const publicIp = require("react-public-ip");


export const EntryFormRedux = ({ errorMsgDisplay, setErrorMsgDisplay, setNextPage, productChoices }) => {

	const dispatch = useDispatch();

	/* Stores all the form data as JSON for validation */
	const [formValues, setFormValues] = useState({});
	const updateFormValues = ( valJSON ) => {
		//logIt( "***** SET VALUES", valJSON, true );
		let fv = formValues;
		fv[valJSON.name] = valJSON;
		setFormValues( fv );
	}
	
	// STATE VARIABLES
	const promotion = useSelector(state => state.promotion);
	//logIt( "PROMOTION at EntryForm", promotion, true );
	
	const entry = useSelector(state => state.entry);
	//logIt( "entry at EntryForm", promotion, true );

	/* Use to get IP information */
	const getIp4 = async version => {
		var the4 = await publicIp.v4() || "";
		return the4;
	};
	
	const ipv4 = getIp4();

	// LOCAL VARIABLES
	const statesConfig = promotion.configuration.config.states;
	
	const currentLanguage = promotion.configuration.currentLanguage;
	
	const fieldsInUse = promotion.configuration.config.fieldsInUse;
	//alert(JSON.stringify(fieldsInUse));

	// const handleSelectChange = (shDivName, dependentFieldName) => {
	// 	// currently, this ONLY shows and hides the passed div - may need to add more functions later
	// 	//alert(shDivName);
	// 	//alert(dependentFieldName);
	// 	let toggle = false;
	// 	let shDiv = document.getElementById(dependentFieldName);
	// 	for ( var i=0; i < shDiv.classList.length; i++ ) {
	// 		if ( shDiv.classList[i] === 'showDiv' ) {
	// 			toggle = true;
	// 		}
	// 	}
	// 	alert( shDiv.id );
	// 	if ( toggle ) {
	// 		//alert(shDiv);
	// 		removeCSSClass( shDiv, 'showDiv' );
	// 		addCSSClass( shDiv, 'hideDiv' );
	// 		//alert("done")
	// 		//removeCSSClass( shDiv, 'showDiv' );
	// 		//addCSSClass( shDiv, 'hideDiv' );
	// 	} else {
	// 		removeCSSClass( shDiv, 'hideDiv' );
	// 		addCSSClass( shDiv, 'showDiv' );
	// 		//removeCSSClass( shDiv, 'hideDiv' );
	// 		//addCSSClass( shDiv, 'showDiv' );
	// 	}
	// }
	
	// TODO: Think about tabIndex, required, errorMsg coming from config and allowed to be left out
	//logIt("FIELDS IN USE", fieldsInUse, true);
	const COMPLETE_FIELD_LIST = {
		"firstName": <FirstName key="firstName" name="firstName" updateFormValues={updateFormValues} fieldsInUse={fieldsInUse} language={currentLanguage} />,
		"lastName": <LastName key="lastName" name="lastName" updateFormValues={updateFormValues} fieldsInUse={fieldsInUse} language={currentLanguage} />,
		"emailAddress": <EmailAddress key="emailAddress" name="emailAddress"  updateFormValues={updateFormValues} fieldsInUse={fieldsInUse} language={currentLanguage} />,
		"address1": <Address1 key="address1" name="address1"  updateFormValues={updateFormValues} fieldsInUse={fieldsInUse} language={currentLanguage} />,
		"address2": <Address2 key="address2" name="address2"  updateFormValues={updateFormValues} fieldsInUse={fieldsInUse} language={currentLanguage} />,
		"city": <City key="city" name="city"  updateFormValues={updateFormValues} fieldsInUse={fieldsInUse} language={currentLanguage} />,
		"state": <State key="state" name="state"  updateFormValues={updateFormValues} fieldsInUse={fieldsInUse} language={currentLanguage} statesConfig={statesConfig} />,
		"postalCode": <PostalCode key="postalCode" name="postalCode"  updateFormValues={updateFormValues} fieldsInUse={fieldsInUse} language={currentLanguage} />,
		"phone": <PhoneInput key="phone" name="phone"  updateFormValues={updateFormValues} fieldsInUse={fieldsInUse} language={currentLanguage} />,
		"dateOfBirth": <DateOfBirth key="dateOfBirth" name="dateOfBirth"  updateFormValues={updateFormValues} fieldsInUse={fieldsInUse} language={currentLanguage} />,
		"userId":  <UserId key="userId" name="userId"  updateFormValues={updateFormValues} fieldsInUse={fieldsInUse} language={currentLanguage} />,
		"optIn1":  <OptIn key="optIn1" name="optIn1"  updateFormValues={updateFormValues} fieldsInUse={fieldsInUse} language={currentLanguage} />,
		"optIn2":  <OptIn key="optIn2" name="optIn2"  updateFormValues={updateFormValues} fieldsInUse={fieldsInUse} language={currentLanguage} />,
		"acceptRules":  <ReadRules key="acceptRules" name="acceptRules"  updateFormValues={updateFormValues} fieldsInUse={fieldsInUse} language={currentLanguage} />,
		"autoOptIn":  <AutoOptIn key="autoOptIn" name="autoOptIn"  updateFormValues={updateFormValues} fieldsInUse={fieldsInUse} language={currentLanguage} />,
		"customText1":  <CustomText key="customText1" name="customText1" updateFormValues={updateFormValues} fieldsInUse={fieldsInUse} language={currentLanguage} />,
		"customText2":  <CustomText key="customText2" name="customText2" updateFormValues={updateFormValues} fieldsInUse={fieldsInUse} language={currentLanguage} />,
		"customText3":  <CustomText key="customText3" name="customText3" updateFormValues={updateFormValues} fieldsInUse={fieldsInUse} language={currentLanguage} />,
		"customText4":  <CustomText key="customText4" name="customText4" updateFormValues={updateFormValues} fieldsInUse={fieldsInUse} language={currentLanguage} />,
		"customText5":  <CustomText key="customText5" name="customText5" updateFormValues={updateFormValues} fieldsInUse={fieldsInUse} language={currentLanguage} />,
		"customSelect1":  <CustomSelect key="customSelect1" name="customSelect1" updateFormValues={updateFormValues} fieldsInUse={fieldsInUse} language={currentLanguage} />,
		"customSelect2":  <CustomSelect key="customSelect2" name="customSelect2" updateFormValues={updateFormValues} fieldsInUse={fieldsInUse} language={currentLanguage} />,
		"customSelect3":  <CustomSelect key="customSelect3" name="customSelect3" updateFormValues={updateFormValues} fieldsInUse={fieldsInUse} language={currentLanguage} />,
		"customSelect4":  <CustomSelect key="customSelect4" name="customSelect4" updateFormValues={updateFormValues} fieldsInUse={fieldsInUse} language={currentLanguage} />,
		"customSelect5":  <CustomSelect key="customSelect5" name="customSelect5" updateFormValues={updateFormValues} fieldsInUse={fieldsInUse} language={currentLanguage} />
	}

	{/** RECAPTCHA V2 **/}
	const recaptchaRef = React.createRef();
	const [gToken, setGToken] = useState("");
	const {executeRecaptcha} = useGoogleReCaptcha();

	{/** RECAPTCHA V3 **/}
	const [gV3Token, setGV3Token] = useState("");
	const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);

	{/** AUTHENTICATION TOKEN **/}
	const token = useSelector(state => state.entry.token);
	const [tokenReady, setTokenReady] = useState(false);

    let btnRef = useRef();
    
    /* Validate all form elements */
    const runValidation = () => {
		// TODO - Look at running validation contained in the form values
    	let foundErr = false;
    	Object.keys(fieldsInUse).map((key, index) => {
    		if ( !foundErr ) {
	    		removeCSSClass( "dv_"+key, "form-error" );
	        	if ( !empty(formValues[key]) && !formValues[key].isValid ) {
	        		setErrorMsgDisplay( formValues[key].valMessage );
	        		addCSSClass( "dv_"+key, "form-error" );
	        		foundErr = true;
	        	}
    		}
    	});
    	if ( foundErr ) {
    		return false;
    	} else {
    		return true;
    	}
    }
    
    const loadEntryObj = (entryObj) => {
    	Object.keys(fieldsInUse).map((key, index) => {
			if ( !empty(formValues[key]) ) {
				if ( !empty(formValues[key].subName) && !empty(formValues[key].subKey) ) {
					entryObj.entry[formValues[key].subKey] = formValues[key].val;
				} else {
					entryObj.entry[key] = formValues[key].val;
				}
			}
    	});
		addPromocracyVariables(entryObj);
    }

	const addPromocracyVariables = (entryObj) => {
		if ( promotion.variables.promocracyOn ) {
			entryObj.entry["promocracyLogoImage"] = promotion.variables.promocracyLogoImage;
			entryObj.entry["promocracyDisplayName"] = promotion.variables.promocracyDisplayName;
			entryObj.entry["promocracyOverallSponsorAddOn"] = promotion.variables.promocracyOverallSponsorAddOn;
			//alert(promotion.configuration.source)
			let promocracyParticipant = retrievePromocracyParticipant(promotion.configuration, promotion.configuration.source);
			if (!empty(promocracyParticipant)) {
				entryObj.entry["promocracyID"] = entryObj.entry.emailAddress + "_" + promocracyParticipant["key"];
			}
		} else {
			entryObj.entry["promocracyDisplayName"] = retrievePromocracyDefaultDisplayName( promotion.configuration );
			entryObj.entry["promocracyOverallSponsorAddOn"] = "";
			entryObj.entry["promocracyID"] = entryObj.entry.emailAddress + "_" + retrievePromocracyDefaultDisplayName( promotion.configuration );
		}
	}
    
    const handleSubmit = async (e) => {

		e.preventDefault();

		// VALIDATE THE FORM
		if (!runValidation()) {
			let isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
			if (!isIE11) {
				btnRef.current.removeAttribute("disabled");
			}
			return;
		}

		// LOAD THE FORM INTO AN ENTRY OBJECT
		let entryObj = {
			entry: {}
		};
		loadEntryObj(entryObj);

		// DISABLE THE SUBMIT BUTTON SO THEY DON'T DOUBLE UP
		var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
		if (!isIE11) {
			if (btnRef.current) {
				if (btnRef.current.getAttribute("disabled") === "disabled") {
					//alert("it's disabled")
					return;
				}
				btnRef.current.setAttribute("disabled", "disabled");
			}
		}

		/** ADD SELENIUM CHECKER **/
		if ( !empty(navigator) && !empty(navigator.webdriver) ) {
			if ( navigator.webdriver === true ) {
				entryObj.nWebdriver = "TRUE";
			}
		}

		// recaptcha
		// if (!executeRecaptcha) {
		// 	var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
		// 	if (!isIE11) {
		// 		btnRef.current.removeAttribute("disabled");
		// 	}
		// 	return;
		// }

		{/** CHECK RECAPTCHA V2 **/}
		const gcToken = await executeRecaptcha("homepage");
		logIt("GOOGLE TOKEN", gcToken, true);
		const recaptchaValue = recaptchaRef != null && recaptchaRef.current != null ? recaptchaRef.current.getValue() : null;
		if (fieldsInUse.recaptchav2 && (fieldsInUse.recaptchav2.on === true) && (empty(gcToken) || empty(recaptchaValue)) ) {
			setErrorMsgDisplay("Please complete the Google captcha.");
			addCSSClass("dv_recaptcha", "form-error");
			return;
		}



		entryObj.entry.ipv4 = ipv4;
		//entryObj.entry.ipv6 = ipv6;
		logIt( "ENTRY TO POST", entryObj, true );

		let gameName = getGameName( promotion );
		let identifier = promotion?.configuration?.config?.identifier;

		//TODO: double check all the values going to postEntry, and throw a true error if any are f-ed

		// EXECUTE THE ENTRY
		if ( fieldsInUse.recaptchav2 && fieldsInUse.recaptchav2.on ) {
			dispatch(postEntry(entryObj, gcToken, recaptchaValue, promotion.configuration.name, gameName, identifier));
		} else {
			if (fieldsInUse?.recaptchav3 && fieldsInUse?.recaptchav3?.on) {
				try {
					dispatch(postEntry(entryObj, gV3Token, recaptchaValue, promotion.configuration.name, gameName, identifier));
				} catch (err) {
					//setRefreshReCaptcha(!refreshReCaptcha);
					logIt("V3 Captcha Error", err, true);
				}
			} else {
				dispatch(postEntry(entryObj, null, null, promotion.configuration.name, gameName, identifier));
			}
		}

	}

	{/** CHECK RECAPTCHA V3 **/}
	const setV3TokenFunc = (getToken) => {
		logIt("V3 Token", getToken, true );
		setGV3Token(getToken);
	};

    const handleNoEmailClick = () => {
    	if ( noEmailMode ) {
    		setNoEmailMode( false );
    	} else {
    		setNoEmailMode( true );
    	}
    }
    
    // const handleBackToLoginClick = () => {
    // 	window.location = '/#/';
    // }

    useEffect(() => {

		if ( !empty(promotion?.configuration?.promoCopy) ) {



			let optIn1Override = getJsonContent(promotion, "Enter", "optIn1OverrideLanguage", "Yes, I <b>would</b> like to receive notifications from the sponsor.", entry);
			setContent("dv_optIn1_override", optIn1Override);

			let optIn2Override = getJsonContent(promotion, "Enter", "optIn2OverrideLanguage", "Yes, I <b>would</b> like to receive notifications from the sponsor #2.", entry);
			setContent("dv_optIn2_override", optIn2Override);

			let readRulesOverride = getJsonContent(promotion, "Enter", "readRulesOverrideLanguage", "Yes, I <b>would</b> like to receive notifications from the sponsor #2.", entry);
			setContent("dv_acceptRules_override", readRulesOverride);

			let autoOptInOverride = getJsonContent(promotion, "Enter", "autoOptInOverrideLanguage", "By entering this promotion you agree to <i>receive emails</i> from company.", entry);
			setContent("dv_autoOptIn_override", autoOptInOverride);
		}

    }, [promotion, entry]);


	{/* SET ERROR MESSAGES AND RE-ENABLE SUBMIT BUTTON */}
    useEffect(() => {
    	if ( !empty(errorMsgDisplay) ) {
        	var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
        	if ( !isIE11 ) {
        		btnRef.current.removeAttribute( "disabled" );
        	}
    	}
    }, [errorMsgDisplay]);

    return (

    	<form>

			{/** FORM ELEMENTS */}
			{( !fieldsInUse.noRegistration ) ?
				(Object.keys(fieldsInUse).map((key, index) => (
					COMPLETE_FIELD_LIST[key]
				 ))) : (
					<div></div>
				)
			}

			{/**GOOGLE RECAPTCHA V2 */}
			<div id="dv_recaptcha" className="form-captcha">
				{ (fieldsInUse.recaptchav2 && fieldsInUse.recaptchav2.on) ? (
					<ReCAPTCHA
					ref={recaptchaRef}
					sitekey="6LeOJewSAAAAAHxouNoE3FDEJUquSwjKb0Zf7v5R"
					onChange={(val) => { console.log(val)}}
				/>) : <React.Fragment></React.Fragment>}
				<GoogleReCaptcha/>
			</div>

			{fieldsInUse?.recaptchav3?.on && <GoogleReCaptchaProvider reCaptchaKey="6LfS2dsUAAAAAIOjcCDAyrl9OC8nApC_wjcT5aFG">
				<GoogleReCaptcha
					className="google-recaptcha-custom-class"
					onVerify={setV3TokenFunc}
					refreshReCaptcha={refreshReCaptcha}
				/>
			</GoogleReCaptchaProvider>}
			{/**GOOGLE RECAPTCHA V3 */}
			{/** <div id="dv_recaptcha_v3" className="form-captcha">
				{ (fieldsInUse.recaptchav3 && fieldsInUse.recaptchav3.on) ? (
					`			<GoogleReCaptchaProvider reCaptchaKey="">
					<GoogleReCaptcha
						className="google-recaptcha-custom-class"
						onVerify={setV3TokenFunc}
						refreshReCaptcha={refreshReCaptcha}
					/>
				</GoogleReCaptchaProvider>`) : <React.Fragment></React.Fragment>}
			</div> **/}

			{/** SUBMIT BUTTON */}
    		<div className="form-submit">
    	        <div className="view-desktop"><input ref={btnRef} type="image" src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}desktop/btn-form-register-submit.png`}  onClick={handleSubmit}/></div>
    	        <div className="view-mobile"><input ref={btnRef} type="image" src={`https://readysetpromo.s3-us-west-2.amazonaws.com/${promotion.configuration.name}/images/${promotion.configuration.languagePath}phone/btn-form-register-submit.png`}  onClick={handleSubmit}/></div>
    		</div>

			{/** ERROR MESSAGE DISPLAY */}
    		{ errorMsgDisplay !== undefined ? (
    			<div className="error">{errorMsgDisplay}</div>
    		) : (
    			<div className="error"></div>
    		)}

    	</form>
    );
    
}

export default EntryFormRedux;



