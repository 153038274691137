import React, { useState, useEffect } from 'react';
import {
    empty
} from '__globals/global-functions';
import {
    getJsonContent, getJsonResultCopy,
    setContent
} from "__globals/copy-functions";

export const ActionButtons = props => {

    // PROMOTION VARIABLES


    // LOCAL VARIABLES
    const [currentPage, setCurrentPage] = useState(undefined);

    const [showActionOne, setShowActionOne] = useState(false);
    const [showActionTwo, setShowActionTwo] = useState(false);
    const [showActionThree, setShowActionThree] = useState(false);

    const [actionOne, setActionOne] = useState('');
    const [valueOne, setValueOne] = useState('');

    const [actionTwo, setActionTwo] = useState('');
    const [valueTwo, setValueTwo] = useState('');

    const [actionThree, setActionThree] = useState('');
    const [valueThree, setValueThree] = useState('');

    /** SET THE CURRENT PAGE **/
    useEffect(() => {
        if (!empty(props.promotion)) {
            if ( !empty(props.promotion.configuration?.config?.pages[props.configKey]) ) {
                setCurrentPage( props.promotion.configuration?.config?.pages[props.configKey] );
            }
        }
    }, [props.promotion]);

    /** GET CONTENT ACTIONS AND VALUE FOR THE BUTTONS **/
    useEffect(() => {
        if (!empty(currentPage)) {
            setShowActionOne( currentPage["actionOne"].show );
            setShowActionTwo( currentPage["actionTwo"].show );
            setShowActionThree( currentPage["actionThree"].show );
            setActionOne( currentPage["actionOne"].action);
            setValueOne( currentPage["actionOne"].value);
            setActionTwo( currentPage["actionTwo"].action);
            setValueTwo( currentPage["actionTwo"].value);
            setActionThree( currentPage["actionThree"].action);
            setValueThree( currentPage["actionThree"].value);
        }
    }, [currentPage]);
    useEffect(() => {
        if (showActionOne && !empty(props.promotion)) {
            let button1Copy = getJsonContent(props.promotion, props.jsonKey, "button1", "<h2>DEFAULT BTN 1</h2>");
            setContent("button_1", button1Copy);
        }
    }, [showActionOne]);
    useEffect(() => {
        if (showActionTwo && !empty(props.promotion)) {
            let button2Copy = getJsonContent(props.promotion, props.jsonKey, "button2", "<h2>DEFAULT BTN 2</h2>");
            setContent("button_2", button2Copy);
        }
    }, [showActionTwo]);
    useEffect(() => {
        if (showActionThree && !empty(props.promotion)) {
            let button3Copy = getJsonContent(props.promotion, props.jsonKey, "button3", "<h2>DEFAULT BTN 3</h2>");
            setContent("button_3", button3Copy);
        }
    }, [showActionThree]);
    /** END GET CONTENT ACTIONS AND VALUE FOR THE BUTTONS **/

    const handleActionButton1 = () => {
        props.doAction( actionOne , valueOne );
    }

    const handleActionButton2 = () => {
        props.doAction( actionTwo , valueTwo );
    }

    const handleActionButton3 = () => {
        props.doAction( actionThree , valueThree );
    }

    return (
        <React.Fragment>
            {showActionOne && <div id="button_1" onClick={handleActionButton1}>Action One</div>}
            {showActionTwo && <div id="button_2" onClick={handleActionButton2}>Action Two</div>}
            {showActionThree && <div id="button_3" onClick={handleActionButton3}>Action Three</div>}
        </React.Fragment>
    );
}